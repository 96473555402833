import React from "react";
import Typewriter from "typewriter-effect";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './Home.css'
import { Card } from "react-bootstrap";
import { Link } from 'react-router-dom'; 
import { useCart } from './CartContext';

export const Home = () => {
const { setCartItems } = useCart();

  const quickOrderDeal = () => {
    setCartItems([{
        id: 1,
        name: 'Gorilla Glue',
        price: 18,
        imageUrl: '/budinglass.png',
        quantity: 1
      }])
  }

  return (
        <Container fluid className='home'>
          <Row className='typewriter-row'>
            <Col></Col>
            <Col lg={7}>
                <h1>
                    <Typewriter
                        options={{
                        strings: ["Weed Near Me NYC"],
                        autoStart: true,
                        loop: true,
                        deleteSpeed: 10
                        }}
                        style={{textAlign: 'center'}}
                    />
                </h1>
            </Col>
            <Col></Col>
         </Row> 
         <Row className='quick-order-row'>
        <Col sm={0} lg={1}></Col>
        <Col sm={12} md={6} lg={3}>
        <Card style={{ backgroundColor: 'white', color: 'black', border: 'solid 1px black', /*marginBottom: '30px',*/ marginTop: '15px' }}>

        {/* <Card style={{ width: '20rem', backgroundColor: 'black', color: '#4AF626', border: 'solid 1px #4AF626', /*marginBottom: '30px', marginTop: '10px'*/ }
            <Card.Body>
            <Card.Img src="/budinbowl.png" />
                <Card.Title style={{padding: 5}}>Get Cannabis Now</Card.Title>
                <Card.Text>
               Find the best weed in NYC. See our full menu here.
                </Card.Text>
                <Link to="/order">
                <Button className="enter-btn" variant="outline-dark">Order Now</Button>
                </Link>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={12} md={6} lg={3}>
        <Card style={{ backgroundColor: 'white', color: 'black', border: 'solid 1px black', /*marginBottom: '30px', */ marginTop: '15px' }}>
            <Card.Body>
                <Card.Img style={{height: '50%'}} src="/budupclose2.png" />
                <Card.Title style={{padding: 5}}>Upcoming 4/20 Events</Card.Title>
                <Card.Text>
               Visit our events and Amsterdam-style coffeeshop.
                </Card.Text>
                <Link to="/comingsoon">
                <Button className="enter-btn" variant="outline-dark">More Info</Button>
                </Link>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={12} md={6} lg={3}>
        <Card style={{ backgroundColor: 'white', color: 'black', border: 'solid 1px black', /*marginBottom: '30px', */ marginTop: '15px' }}>
            <Card.Body>
                <Card.Img src="/quickorderpic.png" />
                <Card.Title style={{padding: 5}}>Need Weed Fast?</Card.Title>
                <Card.Text>
                Click here to quick-order the deal of the day.
                </Card.Text>
                <Link to="/order">
                <Button className="enter-btn" variant="outline-dark" onClick={() => quickOrderDeal()}>Order Now</Button>
                </Link>
            </Card.Body>
        </Card>
        </Col>
        <Col sm={0} lg={1}></Col>
    </Row>
    </Container>
  );
};

export default Home;