import React from 'react';

const About = () => {

  return (
    <div className="order">
        <div>
             </div>
      <div style={{padding: 100, paddingLeft: '15%', fontSize: 'larger'}}>
      <h1>About Us</h1>
        <p>At the Bureau of Cannabis Advocacy NYC, we are dedicated to driving a positive transformation in the lives of those affected by the drug war in New York City. Our mission is to advocate for fairer treatment and improved laws for communities that have borne the brunt of the ravaging consequences of the war on drugs.</p>


<p><h3>Our Purpose:</h3> Our platform was born out of a deep commitment to rectifying the injustices caused by outdated drug policies. Through our advocacy efforts, we strive to bring about meaningful change, uplift communities, and create a space where all voices are heard.</p>


<p><h3>Our Initiatives:</h3> We believe that united action is the catalyst for change. That's why we organize protests, rallies, and community events to raise awareness and foster dialogue around the need for comprehensive drug policy reform. By engaging with the public, candidates, and policymakers, we are determined to bring about legislative changes that will alleviate the impact of the drug war on marginalized communities across the city.</p>


<p><h3>Collaborating for Change:</h3> Our partnerships extend to candidates and policymakers who share our vision of a more just and equitable society. By working together on policy writing and advocacy, we aim to create a legislative landscape that addresses the historical injustices perpetuated by the drug war and provides avenues for restorative justice.</p>


<p><h3>Innovative Connection through WeedNearMeNYC:</h3> As part of our ongoing commitment to foster community and empowerment, we are excited to introduce our groundbreaking initiative, WeedNearMe NYC. This innovative platform connects cannabis users throughout the city, allowing them to share experiences, knowledge, and resources. By building this supportive network, we are nurturing a community that can advocate collectively for fairer cannabis laws while also celebrating the positive impact of cannabis in our lives.</p>


<p><h3>Join Us in Shaping a Brighter Future:</h3> We invite you to join us on this transformative journey. By standing together, we can challenge the status quo, amplify marginalized voices, and inspire comprehensive change. Whether you're a concerned citizen, a passionate advocate, or someone seeking community, Bureau of Cannabis Advocacy NYC welcomes you with open arms. Let's work hand in hand to create a future where justice and compassion thrive.</p>

<p>Stay connected, informed, and inspired through our events, initiatives, and WeedNearMe NYC. Together, we can turn the page on the past and write a future that empowers, uplifts, and unites.</p>


<p><h3>Cannabis for Change NYC: Advocating for Equity, Unity, and Justice.</h3></p>



      </div>
    </div>
  );
};

export default About;
