import React, { useState } from 'react';

const ProductCard = ({ product, onAddToCart }) => {

  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartWithQuantity = () => {
    onAddToCart({ ...product, quantity });
    setQuantity(1); // Reset the quantity to 1 after adding to cart
  };


  return (
    <div className="product-card">
      <img src={product.imageUrl} alt={product.name} />
      <h3>{product.name}</h3>
      <p>${product.price.toFixed(2)}/g</p>
      <div className="quantity-controls">
        <button className='quantity-control' onClick={handleDecrement}>-</button>
        <span>{quantity}</span>
        <button className='quantity-control' onClick={handleIncrement}>+</button>
      </div>
      <button className='add-to-cart' onClick={handleAddToCartWithQuantity}>Add to Cart</button>
    </div>
  
  );
};

export default ProductCard;
