import React, { useEffect } from 'react';
import './CheckoutPage.css';
import 'react-datepicker/dist/react-datepicker.css';
import {InlineWidget} from 'react-calendly'
import { useCart } from './CartContext';

const CheckoutPage = () => {
   const { cartItems } = useCart();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0})
  },[])


  const getTotalPrice = () => {
    const totalPrice = cartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    return totalPrice.toFixed(2);
  };

  const getSelectedItems = () => {
    let cartItemsText = '';
    cartItems.forEach((item) => {
      cartItemsText += `${item.name} - $${(item.price * item.quantity).toFixed(2)} (${item.quantity}x @ $${item.price})\n`;
    });
    return cartItemsText;
  }


  return (
    <div className="checkout-page">
        
        <div className='checkout-header'>
      <h5>Order now, donate when you pick up.</h5>
        <h2>Select a pick up time:</h2> 
                    <InlineWidget prefill={{customAnswers: {a3: getSelectedItems() + '\nTotal: $' + getTotalPrice() }}} url="https://calendly.com/weednearmenyc/10min" />               
                    
        </div>
     </div>
  );
};

export default CheckoutPage;
