import React, { useState } from "react";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import "./Navigation.css";

const Navigation = () => {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <>
      <div className="big-screen-nav">
        <Navbar bg="black">
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/favicon.ico"
              width="30"
              height="30"
              className="d-inline-block align-top"
              style={{ marginLeft: 10 }}
            />{" "}
            WeedNearMeNYC
          </Navbar.Brand>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link className="nav-link" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="nav-link" href="/order">
              Order
            </Nav.Link>
            <Nav.Link className="nav-link" href="/about">
              About
            </Nav.Link>
            <Nav.Link className="nav-link" href="/blog">
              Blog
            </Nav.Link>
            <Nav.Link className="nav-link" href="/comingsoon">
              Events
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
      <div className="small-screen-nav">
        <header className="fixed-top site__header">
          <div className="d-flex align-items-center justify-content-between">
            <Link className="navbar-brand nav_ac small-header" to="/">
              <Image style={{ height: "6vh" }} fluid src="/leafy.png" />
            </Link>
            <div className="d-flex align-items-center">
              <button
                className="menu__button nav_ac"
                title="Navigation menu button"
                onClick={handleToggle}
              >
                {!isActive ? <VscClose /> : <VscGrabber />}
              </button>
            </div>
          </div>

          <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
            <div className="bg__menu h-100">
              <div className="menu__wrapper">
                <div className="menu__container p-3">
                  <ul className="the_menu" style={{ listStyleType: "none" }}>
                    <li className="menu_item my-3">
                      <Link onClick={handleToggle} to="/">
                        Home
                      </Link>
                    </li>
                    <li className="menu_item my-3">
                      <Link onClick={handleToggle} to="/order">
                        Order
                      </Link>
                    </li>
                    <li className="menu_item my-3">
                      <Link onClick={handleToggle} to="/about">
                        About
                      </Link>
                    </li>
                    <li className="menu_item my-3">
                      <Link onClick={handleToggle} to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li className="menu_item my-3">
                      <Link onClick={handleToggle} to="/comingsoon">
                        Events
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
              <div className="d-flex"></div>
              <p className="copyright m-0">paid for by </p>
            </div>
          </div>
        </header>
        <div className="br-top"></div>
        <div className="br-bottom"></div>
        <div className="br-left"></div>
        <div className="br-right"></div>
      </div>
    </>
  );
};

export default Navigation;
