import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../App.css";
import { getMediaList } from "./Blog";
import { TikTokEmbed } from "react-social-media-embed";

const Blogpost = () => {
  const location = useLocation();
  const { postInfo } = location.state;
  const post = postInfo.post;
  const photosVideosWhatever = getMediaList(post.photosVideosWhatever);

  return (
    <Container style={{marginTop: "4.5rem"}} className="blogpost">
      <Row>
        <Col>
          <h1
            style={{
              color: "black",
              textAlign: "center",
              padding: 10,
              paddingTop: 15,
            }}
          >
            {post.title}
          </h1>
          <h4 style={{ color: "black", textAlign: "center", padding: 10 }}>
            {post.subtitle}
          </h4>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-evenly" }}>
        {photosVideosWhatever.map((item) => {
          return (
            <Col md={4}>
              <Image fluid style={{ padding: 15 }} src={item.url} />
            </Col>
          );
        })}
      </Row>
      <Row style={{ padding: 15 }}>
        {post.postText.split("\n").map((paragraph) => {
          return (
            <>
              {paragraph.split(/__/).reduce((a, b, i) => {
                if (i % 2 !== 0) {
                  return (
                    <>
                      <p>{a}</p> <b> {b} </b>
                    </>
                  );
                } else {
                  return (
                    <>
                      <p>
                        {a}
                        {b}
                      </p>
                    </>
                  );
                }
              })}
            </>
          );
        })}
      </Row>
      <Row style={{ justifyContent: "start" }}>
        <h4 style={{ color: "black", textAlign: "start", padding: 10 }}>
          {(post.tiktokUrls || post.instagramUrl) && <>Media: </>}
        </h4>
        <Row style={{ justifyContent: "space-evenly" }}>
          {post.tiktokUrls &&
            post.tiktokUrls.map((url) => <TikTokEmbed url={url} width={325} />)}
          {/* {post.instagramUrl &&
            post.instagramUrl.map((url) => (
              <InstagramEmbed url={url} width={325} />
            ))} */}
        </Row>
      </Row>
    </Container>
  );
};

export default Blogpost;
