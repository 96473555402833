import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import * as contentful from "contentful";
import { Link } from "react-router-dom";
import "../App.css";

const client = contentful.createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
});

const getBlogPosts = async (client) => {
  try {
    const entries = await client.getEntries({
      content_type: "blogPost",
    });
    const filteredEntries = entries.items.map((data) => {
      const filteredEntry = data.fields;
      return filteredEntry;
    });
    return filteredEntries;
  } catch (error) {
    // console.log(error);
  }
};

export const getMediaList = (media) => {
  const mediaItems = media.map((item) => {
    console.log(item);
    const filteredItem = {
      description: item.fields.description,
      contentType: item.fields.file.contentType,
      url: item.fields.file.url,
      alt: item.fields.file.title,
    };
    return filteredItem;
  });
  console.log(mediaItems);
  return mediaItems;
};

const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getBlogPosts(client).then((res) => setPosts(res));
  }, []);

  if (!posts) {
    return <div>No Posts Yet...</div>;
  }

  return (
    <Container style={{marginTop: "4.5rem"}} className="blog">
      <Row style={{ justifyContent: "center" }}>
        {posts.map((post) => (
          <Col sm={10} md={4}>
            <Card style={{ alignItems: "center", margin: 10, padding: 5 }}>
              <Card.Img src={getMediaList(post.photosVideosWhatever)[0].url} />
              <Card.Title style={{ paddingTop: 5 }}>{post.title}</Card.Title>
              <Card.Subtitle style={{ paddingTop: 5 }}>
                {" "}
                {post.subtitle}
              </Card.Subtitle>
              <Link to="/blogpost" state={{ postInfo: { post } }}>
                <button
                  className="blog-post-btn"
                  variant="dark"
                  style={{ margin: 15 }}
                >
                  Read More
                </button>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BlogPage;
