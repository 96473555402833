import React from 'react';

const ComingSoon = () => {

  return (
    <div className="order">
        <div>
             </div>
      <div style={{padding: 100, paddingLeft: '25%', fontSize: 'larger'}}>This page is coming soon!</div>
    </div>
  );
};

export default ComingSoon;
