// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Order from './components/Order';
import Home from './components/Home';
import CheckoutPage from './components/CheckoutPage';
import Navigation from './components/Navigation';
import { CartProvider } from './components/CartContext';
import './App.css'
import ComingSoon from './components/ComingSoon';
import BlogPage from './components/Blog';
import Blogpost from './components/Blogpost';
import About from './components/About';
import { Container } from 'react-bootstrap';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Container fluid className='app-container'>
      <Navigation />
      <CartProvider> 
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/order" element={<Order />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/blog" element={<BlogPage  />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogpost" element={<Blogpost />} />
        </Routes>
      </CartProvider>
      </Container>
    </Router>
  );
};

export default App;
